import React from 'react';

import { ImportantInformation } from '@latitude/important-information';

function BalanceTransferInfoSection({ anchor, data }) {
  return (
    <ImportantInformation
      id={anchor}
      data={{
        content: {
          importantInformationSectionOne: data
        }
      }}
      sectionOneColummClasses="col-10 offset-1 col-lg-10 offset-lg-1"
    />
  );
}

export default BalanceTransferInfoSection;
